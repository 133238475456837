@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&family=Cinzel+Decorative:wght@400;700;900&family=Cinzel:wght@400..900&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
html {
	margin: 0;
	height: 100%;
	overflow: hidden;
}

iframe {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 0;
}

.centered-element {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  /* Add other styles as needed */
}