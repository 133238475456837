.trail {
  position: fixed;
  width: 10px;
  height: 10px;
  background: #ECCFCBFF;
  border-radius: 50%;
  opacity: 0.7;
  pointer-events: none;
  animation: fadeOut 1s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 0;
    transform: scale(0.5);
    opacity: 0;
  }
}

/* Media Query for Mobile */
@media (max-width: 768px) {
  .trail {
    display: none; /* Disable custom cursor and trail on mobile */
  }
}